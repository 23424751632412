@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/@mdi/font@6.4.95/css/materialdesignicons.min.css);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/@mdi/font@6.4.95/css/materialdesignicons.min.css);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/@mdi/font@6.4.95/css/materialdesignicons.min.css);
.v-btn.v-size--x-large{min-width:226px !important}.v-alert__content{font-weight:600}.v-chip{border-width:thin;border-style:solid}.v-card__title{font-family:"Raleway";padding-bottom:0}.v-application .v-card.accent{background-color:#f6ebdf !important}.v-icon svg{height:1em;width:1em;fill:currentColor}.theme--light.v-label{color:rgba(20,47,75,.75)}.v-tooltip__content.menuable__content__active{opacity:.7 !important}.v-tabs-thick .v-tab{background-color:rgba(20,47,75,.5);font-weight:600;font-size:1.1875rem;letter-spacing:0.00625rem;border-bottom:0.1875rem solid #142f4b;padding-left:3rem;padding-right:3rem}.v-tabs-thick .v-tab--active{background-color:#142f4b;color:#fff !important}.v-autocomplete.notransform.v-select--is-menu-active .v-input__icon--append .v-icon{transform:none}.v-data-table-header th{text-transform:uppercase;font-weight:600}.theme--light.v-btn--disabled.v-btn--outlined{color:currentColor !important;opacity:.5}.text-h1{color:#142f4b}.text-h2{color:#142f4b}.text-h3{color:#142f4b}.text-h4{color:#142f4b}.text-h5{color:#142f4b}.text-caption{color:#142f4b}.v-toast__item{max-width:20rem;font-family:"Assistant";align-items:flex-start;padding:1rem;box-shadow:0rem .25rem .5rem .05rem rgba(0,0,0,.5)}.v-toast__item--success{background-color:#84c318}.v-toast__item--warning{background-color:var(--v-secondary-base)}.v-toast__item--error{background-color:#b00020}.v-toast__item .v-toast__text{margin:0;padding:0}.v-toast__item .v-toast__icon{margin:0;margin-right:1rem}.text-formatting--keep{white-space:pre-line}.evaluation-selection-table .v-data-table__wrapper{overflow-x:hidden}.evaluation-selection-table__row{cursor:pointer}.evaluation-selection-table__row--selected{background-color:#d49c5f}.evaluation-selection-table__row--selected:not(:hover){color:#fff}.evaluation-selection-table__row--selected:not(:hover) .v-icon{color:#fff}
.v-btn.v-size--x-large{min-width:226px !important}.v-alert__content{font-weight:600}.v-chip{border-width:thin;border-style:solid}.v-card__title{font-family:"Raleway";padding-bottom:0}.v-application .v-card.accent{background-color:#f6ebdf !important}.v-icon svg{height:1em;width:1em;fill:currentColor}.theme--light.v-label{color:rgba(20,47,75,.75)}.v-tooltip__content.menuable__content__active{opacity:.7 !important}.v-tabs-thick .v-tab{background-color:rgba(20,47,75,.5);font-weight:600;font-size:1.1875rem;letter-spacing:0.00625rem;border-bottom:0.1875rem solid #142f4b;padding-left:3rem;padding-right:3rem}.v-tabs-thick .v-tab--active{background-color:#142f4b;color:#fff !important}.v-autocomplete.notransform.v-select--is-menu-active .v-input__icon--append .v-icon{transform:none}.v-data-table-header th{text-transform:uppercase;font-weight:600}.theme--light.v-btn--disabled.v-btn--outlined{color:currentColor !important;opacity:.5}.text-h1{color:#142f4b}.text-h2{color:#142f4b}.text-h3{color:#142f4b}.text-h4{color:#142f4b}.text-h5{color:#142f4b}.text-caption{color:#142f4b}.v-toast__item{max-width:20rem;font-family:"Assistant";align-items:flex-start;padding:1rem;box-shadow:0rem .25rem .5rem .05rem rgba(0,0,0,.5)}.v-toast__item--success{background-color:#84c318}.v-toast__item--warning{background-color:var(--v-secondary-base)}.v-toast__item--error{background-color:#b00020}.v-toast__item .v-toast__text{margin:0;padding:0}.v-toast__item .v-toast__icon{margin:0;margin-right:1rem}.text-formatting--keep{white-space:pre-line}.evaluation-list__spacer{border-right:1px solid #c4c4c4}
.v-btn.v-size--x-large{min-width:226px !important}.v-alert__content{font-weight:600}.v-chip{border-width:thin;border-style:solid}.v-card__title{font-family:"Raleway";padding-bottom:0}.v-application .v-card.accent{background-color:#f6ebdf !important}.v-icon svg{height:1em;width:1em;fill:currentColor}.theme--light.v-label{color:rgba(20,47,75,.75)}.v-tooltip__content.menuable__content__active{opacity:.7 !important}.v-tabs-thick .v-tab{background-color:rgba(20,47,75,.5);font-weight:600;font-size:1.1875rem;letter-spacing:0.00625rem;border-bottom:0.1875rem solid #142f4b;padding-left:3rem;padding-right:3rem}.v-tabs-thick .v-tab--active{background-color:#142f4b;color:#fff !important}.v-autocomplete.notransform.v-select--is-menu-active .v-input__icon--append .v-icon{transform:none}.v-data-table-header th{text-transform:uppercase;font-weight:600}.theme--light.v-btn--disabled.v-btn--outlined{color:currentColor !important;opacity:.5}.text-h1{color:#142f4b}.text-h2{color:#142f4b}.text-h3{color:#142f4b}.text-h4{color:#142f4b}.text-h5{color:#142f4b}.text-caption{color:#142f4b}.v-toast__item{max-width:20rem;font-family:"Assistant";align-items:flex-start;padding:1rem;box-shadow:0rem .25rem .5rem .05rem rgba(0,0,0,.5)}.v-toast__item--success{background-color:#84c318}.v-toast__item--warning{background-color:var(--v-secondary-base)}.v-toast__item--error{background-color:#b00020}.v-toast__item .v-toast__text{margin:0;padding:0}.v-toast__item .v-toast__icon{margin:0;margin-right:1rem}.text-formatting--keep{white-space:pre-line}.evaluation-overview__evaluation-list{height:100vh}

/*# sourceMappingURL=group-evaluation.63bd4dfc.css.map*/