@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































.evaluation-list__spacer {
  border-right: 1px solid $grey-3;
}
