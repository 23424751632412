@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































.evaluation-overview__evaluation-list {
  height: 100vh;
}
